// Angular Imports
import {
	Component,
	ChangeDetectionStrategy,
	inject,
	viewChild,
	effect,
	ElementRef,
	computed,
} from '@angular/core'
import { Router, RouterLink } from '@angular/router'
// Shared Imports
import { UiTheme, NavigationItem, Icons, TranslocoLanguageKey } from '@shared/models'
import {
	ButtonContactmeComponent,
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
} from '@shared/components/independents'
import { UiStore } from '@shared/store'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

const components = [ButtonContactmeComponent, LinkComponent, IconComponent, ButtonIconComponent]

@Component({
	selector: 'gld-navbar',
	standalone: true,
	imports: [TranslocoDirective, RouterLink, ...components],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
	readonly #router = inject(Router)
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly TranslocoLanguageKey = TranslocoLanguageKey
	readonly Icons = Icons

	theme = computed<UiTheme>(() => this.#uiStore.theme())
	language = computed<TranslocoLanguageKey>(() => this.#uiStore.language())
	navigationIcons = viewChild.required<ElementRef>('navigationIcons')

	items = computed<NavigationItem[]>(() => this.#uiStore.navigation().items)

	constructor() {
		// Center navbar icons
		effect(() => {
			const navigationIcons = this.navigationIcons().nativeElement
			const clientWidth = navigationIcons.clientWidth
			navigationIcons.style.setProperty('--icons-width', `${clientWidth}px`)
		})
	}

	toggleTheme(): void {
		if (this.#uiStore.theme() === UiTheme.light) this.#uiStore.changeTheme(UiTheme.dark)
		else this.#uiStore.changeTheme(UiTheme.light)
	}

	toggleLanguage(): void {
		if (this.#uiStore.language() === TranslocoLanguageKey.english)
			this.#uiStore.changeLanguage(TranslocoLanguageKey.spanish)
		else this.#uiStore.changeLanguage(TranslocoLanguageKey.english)
	}

	activeRoute(route: string[]): boolean {
		return this.#router.isActive(route.join('/'), false)
	}

	navigateToRoute(route: string[]) {
		this.#router.navigate(route)
	}

	openNavigation(): void {
		this.#uiStore.openNavigation()
	}
}

