<dialog
	#modal
	[tabindex]="opened() ? '0' : '-1'"
	[class.Theme--dark]="theme() === UiTheme.dark"
	(keyup.escape)="closeModal()"
	(click)="closeModalByBackdrop($event)"
>
	<gld-tile color="blue-to-green" [theme]="theme()">
		<article
			*transloco="let transloco; prefix: 'shared.components.compounds.modals'"
			class="Modal"
			[class.Theme--dark]="theme() === UiTheme.dark"
		>
			<header class="Modal-header">
				<h2 class="Modal-header-title">
					{{ transloco('contactme.label') }}
				</h2>

				<!-- Close button -->
				<gld-button-icon
					active
					name="Close"
					iconSize="normal"
					[theme]="theme()"
					[icon]="Icons.closeLine"
					(clicked)="closeModal()"
				/>
			</header>

			<section class="Modal-group">
				<section
					tabindex="1"
					aria-label="curriculum preview"
					class="Modal-preview"
					(keyup)="openPdf(language())"
					(click)="openPdf(language())"
				>
					<section class="Modal-preview-overlay">
						<gld-icon gldIcon [icon]="Icons.eyeLine" size="large" matTooltip="Ver PDF" />
					</section>
					<gld-image
						class="image"
						[src]="transloco('contactme.imagePreview')"
						[alt]="transloco('contactme.imagePreviewLabel')"
					/>
				</section>

				<section class="Modal-actions">
					<!-- <gld-button hasIcon size="large" color="purple-to-pink" [theme]="theme()" [icon]="Icons.downloadCloudLine"
              (clicked)="openPdf(language())">
              {{ transloco('contactme.downloadLabel') }}
            </gld-button> -->
					<section class="Modal-socials">
						@for (social of socials(); track social) {
							<gld-link external [redirect]="social.link">
								<gld-button-icon
									iconSize="normal"
									[name]="social.name"
									[theme]="theme()"
									[icon]="social.icon"
								/>
							</gld-link>
						}
					</section>
				</section>
			</section>
		</article>
	</gld-tile>
</dialog>

