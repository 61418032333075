@if (external()) {
	<!-- It's external link -->
	<ng-container
		[ngTemplateOutlet]="buttonTemplate"
		[ngTemplateOutletContext]="{ buttonType: 'anchor' }"
	/>
} @else {
	<!-- It isn't external link -->
	<ng-container
		[ngTemplateOutlet]="buttonTemplate"
		[ngTemplateOutletContext]="{ buttonType: 'button' }"
	/>
}

<!-- Button Template -->
<ng-template #buttonTemplate let-buttonType="buttonType">
	@switch (buttonType) {
		@case ('anchor') {
			<a
				class="Link Link--{{ align() }}"
				[class.Theme--dark]="theme() === UiTheme.dark"
				[class.Link--decorated]="type() === LinkType.decorated"
				[class.Link--active]="active()"
				(click)="emitClick()"
				[href]="redirect()"
				target="_blank"
			>
				<ng-container [ngTemplateOutlet]="contentButtonTemplate" />
			</a>
		}
		@case ('button') {
			@if (redirect()) {
				<!-- It has path to redirect -->
				<button
					class="Link Link--{{ align() }}"
					[class.Theme--dark]="theme() === UiTheme.dark"
					[class.Link--decorated]="type() === LinkType.decorated"
					[class.Link--active]="active()"
					(click)="emitClick()"
					[routerLink]="redirect()"
				>
					<ng-container [ngTemplateOutlet]="contentButtonTemplate" />
				</button>
			} @else {
				<!-- It doesn't have path to redirect -->
				<button
					class="Link Link--{{ align() }}"
					[class.Theme--dark]="theme() === UiTheme.dark"
					[class.Link--decorated]="type() === LinkType.decorated"
					[class.Link--active]="active()"
					(click)="emitClick()"
				>
					<ng-container [ngTemplateOutlet]="contentButtonTemplate" />
				</button>
			}
		}
	}
</ng-template>

<!-- Content Button Template -->
<ng-template #contentButtonTemplate>
	<ng-content select="[gldIcon]" />
	<span class="Link-gradient">
		<ng-content />
	</span>
</ng-template>

