<article class="Wrapper" [class.Wrapper--opened]="opened()">
	<!-- Overlay -->
	<section
		aria-hidden="true"
		class="Wrapper-overlay"
		[class.Wrapper-overlay--opened]="opened()"
		[class.Theme--dark]="theme() === UiTheme.dark"
		(click)="closeNavigation()"
	></section>

	<!-- Sidebar -->
	<nav
		class="Sidebar"
		[class.Sidebar--opened]="opened()"
		[class.Theme--dark]="theme() === UiTheme.dark"
	>
		<!-- Header -->
		<header class="Sidebar-header">
			<!-- Close button -->
			<gld-button-icon
				active
				name="Close"
				iconSize="small"
				[theme]="theme()"
				[icon]="Icons.closeLine"
				(clicked)="closeNavigation()"
			/>
		</header>

		<!-- Items -->
		<section class="Sidebar-items">
			@for (item of items(); track item) {
				@if (item?.hasSubitems) {
					<button
						aria-hidden="true"
						class="Sidebar-item"
						[class.Sidebar-item--opened]="item?.opened"
						(click)="toggleItem(item)"
					>
						<gld-icon
							gldIcon
							size="small"
							class="Sidebar-item-prefix"
							[icon]="item.icon"
							[moveTopToBotton]="-1"
						/>

						<span class="Sidebar-item-gradient">{{ item.label }}</span>

						<gld-icon
							gldIcon
							size="small"
							class="Sidebar-item-suffix Animation-rotation"
							[class.Animation-rotation--rotated]="item.opened"
							[icon]="Icons.arrowDownSLine"
						/>
					</button>

					<section class="Sidebar-subitems" [@smoothCollapse]="item.opened ? 'show' : 'hidden'">
						@for (subitem of item?.subitems; track subitem) {
							<button
								aria-hidden="true"
								class="Sidebar-item"
								[class.Sidebar-item--active]="activeRoute(subitem.route)"
								(click)="navigateToRoute(subitem.route)"
							>
								<gld-icon
									gldIcon
									size="small"
									class="Sidebar-item-prefix"
									[icon]="subitem.icon"
									[moveTopToBotton]="-1"
								/>

								<span class="Sidebar-item-gradient">{{ subitem.label }}</span>
							</button>
						}
					</section>
				} @else {
					<button
						aria-hidden="true"
						class="Sidebar-item"
						[class.Sidebar-item--active]="activeRoute(item.route)"
						(click)="navigateToRoute(item.route)"
					>
						<gld-icon
							gldIcon
							size="small"
							class="Sidebar-item-prefix"
							[icon]="item.icon"
							[moveTopToBotton]="-1"
						/>

						<span class="Sidebar-item-gradient">{{ item.label }}</span>
					</button>
				}
			}
		</section>

		<!-- Footer -->
		<footer class="Sidebar-footer">
			<span>v{{ version() }}</span>
		</footer>
	</nav>
</article>

