// Angular Imports
import { ChangeDetectionStrategy, Component, booleanAttribute, input, output } from '@angular/core'
// This Module Imports
import { IconComponent } from '../../icon/icon.component'
// Shared Imports
import { Icons, IconsSize, UiTheme } from '@shared/models'

@Component({
	selector: 'gld-button-icon',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './button-icon.component.html',
	styleUrl: './button-icon.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent {
	readonly UiTheme = UiTheme

	name = input.required<string>()
	icon = input.required<Icons>()
	iconSize = input<keyof typeof IconsSize>(IconsSize.normal)
	theme = input<UiTheme>(UiTheme.light)
	active = input<boolean, string | boolean>(false, { transform: booleanAttribute })
	clicked = output<void>()

	emitClick() {
		this.clicked.emit()
	}
}

