import { type Environment, EnvironmentType } from '@shared/models'

export const environment: Environment = {
	environment: EnvironmentType.production,
	production: true,
	iamgldApi: 'https://api.iamgld.dev',
	cloudflareIamgldImagesBucket: 'https://images.iamgld.dev',
	githubApi: 'https://api.github.com',
	githubApiPersonalAccessToken: '',
}
