<article
	class="Page"
	[class.Theme--dark]="theme() === UiTheme.dark"
	*transloco="let transloco; scope: 'shared'"
>
	<h2 class="Page-title">
		<gld-icon gldIcon [icon]="Icons.pagesLine" size="small" />
		{{ transloco('shared.components.independents.pageUnderDevelopment.label') }}
	</h2>
</article>
