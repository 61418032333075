// Angular Imports
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core'
// Shared Imports
import { UiTheme } from '@shared/models'
// Thirdparty Imports
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco'

@Component({
	standalone: true,
	selector: 'gld-button-contactme',
	imports: [TranslocoDirective],
	templateUrl: './button-contactme.component.html',
	styleUrl: './button-contactme.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [provideTranslocoScope('shared')],
})
export class ButtonContactmeComponent {
	readonly UiTheme = UiTheme

	theme = input<UiTheme>(UiTheme.light)
	clicked = output<void>()

	onClick() {
		this.clicked.emit()
	}
}


