// Angular Imports
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
// Shared Imports
import { Icons, UiTheme } from '@shared/models'
import { IconComponent } from '../icon/icon.component'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

@Component({
	selector: 'gld-page-under-development',
	standalone: true,
	imports: [TranslocoDirective, IconComponent],
	templateUrl: './page-under-development.component.html',
	styleUrl: './page-under-development.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageUnderDevelopmentComponent {
	readonly Icons = Icons
	readonly UiTheme = UiTheme

	theme = input<UiTheme>(UiTheme.light)
}
