// Angular Imports
import {
	ChangeDetectionStrategy,
	Component,
	booleanAttribute,
	input,
	numberAttribute,
} from '@angular/core'
// Shared Imports
import { Icons, IconsSize } from '@shared/models'

@Component({
	selector: 'gld-icon',
	standalone: true,
	imports: [],
	templateUrl: './icon.component.html',
	styleUrl: './icon.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
	readonly IconsSpace = IconsSpace

	icon = input<Icons>(Icons.userLine)
	size = input<keyof typeof IconsSize>(IconsSize.normal)
	space = input<keyof typeof IconsSpace>(IconsSpace.none)
	gradient = input<boolean, string | boolean>(false, { transform: booleanAttribute })
	moveTopToBotton = input<number, string | number>(0, { transform: numberAttribute })
	moveLeftToRight = input<number, string | number>(0, { transform: numberAttribute })
}

enum IconsSpace {
	left = 'left',
	right = 'right',
	none = 'none',
}
