// Angular Imports
import { Injectable, type Renderer2, RendererFactory2, inject } from '@angular/core'
// Shared Imports
import { UiTheme } from '@shared/models'

@Injectable({ providedIn: 'root' })
export class UiService {
	readonly #rendererFactory = inject(RendererFactory2)

	#renderer: Renderer2
	#allThemes: UiTheme[] = [UiTheme.dark, UiTheme.light]

	constructor() {
		this.#renderer = this.#rendererFactory.createRenderer(null, null)
	}

	changeTheme(theme: UiTheme): void {
		// Remove all previous themes
		this.#allThemes.map((theme: UiTheme) => this.#renderer.removeClass(document.body, theme))
		// Add the new theme
		this.#renderer.addClass(document.body, theme)
	}
}
