<button
	aria-label="contactme button"
	class="Button"
	[class.Theme--dark]="theme() === UiTheme.dark"
	*transloco="let transloco; prefix: 'shared.components.independents.buttons'"
	(keyup)="onClick()"
	(click)="onClick()"
>
	{{ transloco('contactme.label') }}
</button>

