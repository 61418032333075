// Shared Imports
import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// Shared Imports
import type { TranslocoLanguageKey } from '@shared/models'
// Thirdparty Imports
import {
	TranslocoService as NgneatTranslocoService,
	type Translation,
	type TranslocoLoader,
} from '@jsverse/transloco'

@Injectable({
	providedIn: 'root',
})
export class TranslocoService {
	readonly #translocoService = inject(NgneatTranslocoService)

	changeLanguage(language: TranslocoLanguageKey) {
		this.#translocoService.setActiveLang(language)
	}

	getLanguage(): TranslocoLanguageKey {
		return this.#translocoService.getActiveLang() as TranslocoLanguageKey
	}
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	readonly #httpClient = inject(HttpClient)

	getTranslation(language: string) {
		return this.#httpClient.get<Translation>(`/assets/i18n/${language}.json`)
	}
}
