// Angular Imports
import { Component, signal, ChangeDetectionStrategy, inject, computed } from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
// Shared Imports
import { Icons, TranslocoLanguageKey, UiTheme } from '@shared/models'
import { LinkComponent, ButtonIconComponent, IconComponent } from '@shared/components/independents'
import { UiStore } from '@shared/store'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

const components = [LinkComponent, IconComponent, ButtonIconComponent]

@Component({
	selector: 'gld-footer',
	standalone: true,
	imports: [NgTemplateOutlet, TranslocoDirective, ...components],
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly Icons = Icons
	readonly TranslocoLanguageKey = TranslocoLanguageKey

	theme = computed<UiTheme>(() => this.#uiStore.theme())
	language = computed<TranslocoLanguageKey>(() => this.#uiStore.language())
	currentYear = signal<number>(new Date().getFullYear())

	contactsEnglish = signal<Contact[]>([
		{
			action: ContactAction.maps,
			copy: 'from Venezuela to the world',
			icon: Icons.earthLine,
			link: 'https://www.google.com.ar/maps/place/Buenos+Aires,+CABA/@-34.6154611,-58.5733846,11z/data=!3m1!4b1!4m5!3m4!1s0x95bcca3b4ef90cbd:0xa0b3812e88e88e87!8m2!3d-34.6036844!4d-58.3815591',
		},
		{
			action: ContactAction.mail,
			copy: 'contact@iamgld.dev',
			icon: Icons.mailSendLine,
			link: "mailto:contact@iamgld.dev?subject=I'll buy you a coffee and we'll talk!",
		},
	])
	contactsSpanish = signal<Contact[]>([
		{
			action: ContactAction.maps,
			copy: 'de Venezuela para el mundo',
			icon: Icons.earthLine,
			link: 'https://www.google.com.ar/maps/place/Buenos+Aires,+CABA/@-34.6154611,-58.5733846,11z/data=!3m1!4b1!4m5!3m4!1s0x95bcca3b4ef90cbd:0xa0b3812e88e88e87!8m2!3d-34.6036844!4d-58.3815591',
		},
		{
			action: ContactAction.mail,
			copy: 'contact@iamgld.dev',
			icon: Icons.mailSendLine,
			link: 'mailto:contact@iamgld.dev?subject=Te invito un cafe y conversamos!',
		},
	])

	socials = signal<Social[]>([
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/in/iamgld/',
			icon: Icons.linkedinBoxFill,
			username: 'iamgld',
		},
		{
			name: 'GitHub',
			link: 'https://github.com/iamgld',
			icon: Icons.githubFill,
			username: 'iamgld',
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/iamgld_',
			icon: Icons.instagramFill,
			username: 'iamgld_',
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/iamgld_',
			icon: Icons.twitterFill,
			username: 'iamgld_',
		},
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/iamgld',
			icon: Icons.facebookCircleFill,
			username: 'iamgld',
		},
	])
}

interface Contact {
	action: ContactAction
	icon: Icons
	link: string
	copy: string
}

enum ContactAction {
	maps = 'maps',
	mail = 'mail',
}

interface Social {
	name: string
	icon: Icons
	link: string
	username: string
}

