// Shared Imports
import type { Icons } from '@shared/models'

export enum UiTheme {
	dark = 'Theme--dark',
	light = 'Theme--light',
}

export interface NavigationItem {
	route: string[]
	label: string
	name: string
	icon: Icons
	opened?: boolean
	hasSubitems?: boolean
	subitems?: NavigationSubitem[]
}
export interface NavigationSubitem {
	route: string[]
	label: string
	name: string
	icon: Icons
	opened?: boolean
}

