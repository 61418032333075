// Angular Imports
import { Directive, ElementRef, HostListener, inject, input } from '@angular/core'

@Directive({
	selector: '[gldImageBroken]',
	standalone: true,
})
export class ImageBrokenDirective {
	readonly #host = inject(ElementRef)

	fallbackImage = input<string>('assets/svgs/icons/fire-icon.svg')

	@HostListener('error') handleError(): void {
		const nativeElement = this.#host.nativeElement
		// console.log('🔴 Crash image -->', this._host)
		nativeElement.src = this.fallbackImage()
	}
}
