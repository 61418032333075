// Store Imports
import { inject } from '@angular/core'
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals'
// Shared Imports
import { Icons, NavigationItem, TranslocoLanguageKey, UiTheme } from '@shared/models'
import { UiService, TranslocoService } from '@shared/services'

export interface UiState {
	theme: UiTheme
	language: TranslocoLanguageKey
	navigation: {
		opened: boolean
		items: NavigationItem[]
	}
}

export const initialState: UiState = {
	theme: UiTheme.dark,
	language: TranslocoLanguageKey.english,
	navigation: {
		opened: false,
		items: [
			{ route: ['/me'], label: 'Me', name: 'me', icon: Icons.userSmileLine },
			{
				route: ['/resources'],
				label: 'Resources',
				name: 'resources',
				icon: Icons.shapes,
				opened: false,
				hasSubitems: true,
				subitems: [
					{
						route: ['/resources'],
						label: 'Libraries',
						name: 'libraries',
						icon: Icons.book2Line,
					},
					{
						route: ['/resources'],
						label: 'Articles',
						name: 'articles',
						icon: Icons.bookReadLine,
					},
					{
						route: ['/resources'],
						label: 'Code Fragments',
						name: 'code-fragments',
						icon: Icons.codeSlashLine,
					},
				],
			},
		],
	},
}

export const UiStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withHooks({
		onInit(store, translocoService = inject(TranslocoService)) {
			translocoService.changeLanguage(store.language())
		},
	}),
	withMethods(
		(store, uiService = inject(UiService), translocoService = inject(TranslocoService)) => ({
			changeTheme: (theme: UiTheme): void => {
				uiService.changeTheme(theme)
				patchState(store, () => ({ theme }))
			},
			changeLanguage: (language: TranslocoLanguageKey): void => {
				translocoService.changeLanguage(language)
				patchState(store, () => ({ language }))
			},
			openNavigation: (): void => {
				patchState(store, (previous: UiState) => ({
					...previous,
					navigation: { ...previous.navigation, opened: true },
				}))
			},
			closeNavigation: (): void => {
				patchState(store, (previous: UiState) => ({
					...previous,
					navigation: { ...previous.navigation, opened: false },
				}))
			},
			toggleNavigationItem: (item: NavigationItem): void => {
				patchState(store, (previous: UiState) => {
					const itemsUpdated: NavigationItem[] = previous.navigation.items.map(
						(_item: NavigationItem) => {
							if (_item === item) _item.opened = !_item.opened
							return _item
						},
					)
					return {
						...previous,
						navigation: { ...previous.navigation, items: itemsUpdated },
					}
				})
			},
		}),
	),
)

