// Angular Imports
import { ChangeDetectionStrategy, Component, booleanAttribute, input } from '@angular/core'
// Shared Imports
import { type TileColor, UiTheme } from '@shared/models'

@Component({
	selector: 'gld-tile',
	standalone: true,
	imports: [],
	templateUrl: './tile.component.html',
	styleUrl: './tile.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {
	readonly UiTheme = UiTheme

	theme = input<UiTheme>(UiTheme.light)
	// hover = input<boolean, boolean | string>(false, { transform: booleanAttribute })
	blur = input<boolean, boolean | string>(false, { transform: booleanAttribute })
	color = input<TileColor>('blue-to-green')
	// hoverColor = input<TileColor>(TileColor.blueToGreen)
}
