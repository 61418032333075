// Angular Imports
import { Component, ChangeDetectionStrategy, inject, computed, signal } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'
// Shared Imports
import { UiTheme, Icons, NavigationItem } from '@shared/models'
import { IconComponent, ButtonIconComponent } from '@shared/components/independents'
import { UiStore } from '@shared/store'

const components = [IconComponent, ButtonIconComponent]

@Component({
	selector: 'gld-sidebar',
	standalone: true,
	imports: [RouterLink, RouterLinkActive, ...components],
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('smoothCollapse', [
			state(
				'hidden',
				style({
					height: 0,
					opacity: 0,
					visibility: 'hidden',
				}),
			),
			state('show', style({})),
			transition('hidden <=> show', animate('150ms')),
		]),
	],
})
export class SidebarComponent {
	readonly #router = inject(Router)
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly Icons = Icons

	theme = computed<UiTheme>(() => this.#uiStore.theme())
	opened = computed<boolean>(() => this.#uiStore.navigation().opened)
	items = computed<NavigationItem[]>(() => this.#uiStore.navigation().items)

	version = signal<string>('3.5.0')

	closeNavigation(): void {
		this.#uiStore.closeNavigation()
	}

	navigateToRoute(route: string[]): void {
		this.#router.navigate(route)
		this.closeNavigation()
	}

	toggleItem(item: NavigationItem) {
		this.#uiStore.toggleNavigationItem(item)
	}

	activeRoute(route: string[]): boolean {
		return this.#router.isActive(route.join('/'), false)
	}
}

