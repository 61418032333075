export interface Environment {
	environment: EnvironmentType
	production: boolean
	iamgldApi: string
	cloudflareIamgldImagesBucket: string
	githubApi: string
	githubApiPersonalAccessToken: string
}

export enum EnvironmentType {
	production = 'production',
	staging = 'staging',
	development = 'development',
	local = 'local',
}
