// Angular Imports
import { ChangeDetectionStrategy, Component, type OnInit, signal } from '@angular/core'
import { environment } from '@environment'
// Shared Imports
import { EnvironmentType } from '@shared/models'

@Component({
	standalone: true,
	selector: 'gld-environment-decorator',
	imports: [],
	templateUrl: './environment-decorator.component.html',
	styleUrl: './environment-decorator.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentDecoratorComponent implements OnInit {
	readonly EnvironmentType = EnvironmentType

	environmentLabel = signal<EnvironmentType>(EnvironmentType.local)
	hidden = signal<boolean>(false)
	version = signal<string>('3.5.0')

	ngOnInit(): void {
		// Set current environment
		switch (environment.environment) {
			case EnvironmentType.local:
				this.environmentLabel.set(EnvironmentType.local)
				break
			case EnvironmentType.development:
				this.environmentLabel.set(EnvironmentType.development)
				break
			case EnvironmentType.staging:
				this.environmentLabel.set(EnvironmentType.staging)
				break
			case EnvironmentType.production:
				this.environmentLabel.set(EnvironmentType.production)
				break
		}
	}

	onHiddenDecoratorFor10Seg() {
		this.hidden.set(true)
		window.setTimeout(() => {
			this.hidden.set(false)
		}, 200000)
	}
}

