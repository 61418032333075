@if (environmentLabel() !== EnvironmentType.production) {
	<article
		aria-hidden="true"
		class="Decorator"
		[class.Decorator--hidden]="hidden()"
		(click)="onHiddenDecoratorFor10Seg()"
	>
		<section class="Decorator-labels">
			<span class="Decorator-label Decorator-label--lowercase">v{{ version() }}</span>
			<span class="Decorator-label">{{ environmentLabel() }}</span>
		</section>
	</article>
}

