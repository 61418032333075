// Angular Imports
import {
	Component,
	signal,
	ChangeDetectionStrategy,
	inject,
	computed,
	input,
	output,
	viewChild,
	effect,
	ElementRef,
} from '@angular/core'
// Shared Imports
import {
	ButtonComponent,
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
	ImageComponent,
	TileComponent,
} from '@shared/components/independents'
import { Icons, TranslocoLanguageKey, UiTheme } from '@shared/models'
import { UiStore } from '@shared/store'
// Thirdparty Imports
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco'

const components = [
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
	ButtonComponent,
	ImageComponent,
	TileComponent,
]

@Component({
	selector: 'gld-modal-contactme',
	standalone: true,
	imports: [TranslocoDirective, ...components],
	templateUrl: './modal-contactme.component.html',
	styleUrl: './modal-contactme.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [provideTranslocoScope('shared')],
})
export class ModalContactmeComponent {
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly Icons = Icons
	readonly TranslocoLanguageKey = TranslocoLanguageKey

	open = input<boolean>(false)
	closed = output<void>()

	modal = viewChild.required<ElementRef<HTMLDialogElement>>('modal')
	opened = signal<boolean>(false)
	theme = computed<UiTheme>(() => this.#uiStore.theme())
	language = computed<TranslocoLanguageKey>(() => this.#uiStore.language())
	socials = signal<Social[]>([
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/in/iamgld/',
			icon: Icons.linkedinBoxFill,
			username: 'iamgld',
		},
		{
			name: 'GitHub',
			link: 'https://github.com/iamgld',
			icon: Icons.githubFill,
			username: 'iamgld',
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/iamgld_',
			icon: Icons.instagramFill,
			username: 'iamgld_',
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/iamgld_',
			icon: Icons.twitterFill,
			username: 'iamgld_',
		},
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/iamgld',
			icon: Icons.facebookCircleFill,
			username: 'iamgld',
		},
	])

	constructor() {
		effect(() => {
			if (this.open()) this.openModal()
		})
	}

	openPdf(language: TranslocoLanguageKey): void {
		if (language === TranslocoLanguageKey.english)
			window.open('assets/pdfs/curriculum/iamgld-cv-english.pdf', '_blank')
		else window.open('assets/pdfs/curriculum/iamgld-cv-spanish.pdf', '_blank')
	}

	openModal(): void {
		this.modal().nativeElement.showModal()
		this.opened.set(true)
	}

	closeModal(): void {
		this.modal().nativeElement.close()
		this.opened.set(false)
		this.closed.emit()
	}

	closeModalByBackdrop(event: MouseEvent) {
		const modalDimensions = this.modal().nativeElement.getBoundingClientRect()
		if (
			event.clientX < modalDimensions.left ||
			event.clientX > modalDimensions.right ||
			event.clientY < modalDimensions.top ||
			event.clientY > modalDimensions.bottom
		) {
			this.closeModal()
		}
	}
}

interface Social {
	name: string
	icon: Icons
	link: string
	username: string
}

