export enum Icons {
	sunLine = 'ri-sun-line',
	moonClearLine = 'ri-moon-clear-line',
	homeLine = 'ri-home-line',
	home5Line = 'ri-home-5-line',
	menu3Line = 'ri-menu-3-line',
	bookReadLine = 'ri-book-read-line',
	bookOpenLine = 'ri-book-open-line',
	book2Line = 'ri-book-2-line',
	closeLine = 'ri-close-line',
	userLine = 'ri-user-line',
	logoutCircleLine = ' ri-logout-circle-line',
	addLine = 'ri-add-line',
	mailSendLine = 'ri-mail-send-line',
	downloadCloudLine = 'ri-download-cloud-line',
	userSmileLine = 'ri-user-smile-line',
	gitRepositoryLine = 'ri-git-repository-line',
	eyeLine = 'ri-eye-line',
	gitBranchLine = 'ri-git-branch-line',
	starSmileLine = 'ri-star-smile-line',
	starLine = 'ri-star-line',
	calendarTodoLine = 'ri-calendar-todo-line',
	earthLine = 'ri-earth-line',
	facebookCircleFill = 'ri-facebook-circle-fill',
	instagramFill = 'ri-instagram-fill',
	twitterFill = 'ri-twitter-fill',
	gitlabFill = 'ri-gitlab-fill',
	githubFill = 'ri-github-fill',
	linkedinBoxFill = 'ri-linkedin-box-fill',
	downloadCloud2Line = 'ri-download-cloud-2-line',
	eyeOffLine = 'ri-eye-off-line',
	pagesLine = 'ri-pages-line',
	translate = 'ri-translate',
	englishInput = 'ri-english-input',
	refreshLine = 'ri-refresh-line',
	clipboard = 'ri-clipboard-line',
	profile = 'ri-profile-line',
	links = 'ri-links-fill',
	shapes = 'ri-shapes-line',
	group = 'ri-group-line',
	stack = 'ri-stack-line',
	circle = 'ri-circle-line',
	square = 'ri-square-line',
	triangle = 'ri-triangle-line',
	bugLine = 'ri-bug-line',
	codeSlashLine = 'ri-code-s-slash-line',
	arrowDownSLine = 'ri-arrow-down-s-line',
	arrowUpSLine = 'ri-arrow-up-s-line',
}

export enum IconsSize {
	tiny = 'tiny',
	small = 'small',
	normal = 'normal',
	medium = 'medium',
	large = 'large',
}
